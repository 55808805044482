export enum HealthcareSpecialty {
  Cardiology = 'cardiology',
  Oncology = 'oncology',
  Dermatology = 'dermatology',
  Neurology = 'neurology',
  Pediatrics = 'pediatrics',
  Orthopedics = 'orthopedics',
  Gastroenterology = 'gastroenterology',
  Psychiatry = 'psychiatry',
  Ophthalmology = 'ophthalmology',
  Radiology = 'radiology',
  Endocrinology = 'endocrinology',
  Urology = 'urology',
  Hematology = 'hematology',
  Pulmonology = 'pulmonology',
}
