import {
  Text, Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { images } from '../shared/utils/public'

export default function PatientApplicationComplete() {
  const { t } = useTranslation()
  return (
    <div className="2xl:w-5/12 md:w-8/12 sm:w-7/12 flex flex-col gap-5 sm:mx-auto mx-6 my-4">
      <div className="flex flex-col items-center">
        <img
          className="md:h-65 h-35 object-contain my-5"
          src={images.clipboardWithCubes.src}
          alt={images.clipboardWithCubes.alt}
        />
        <div className="flex flex-col gap-2 items-center">
          <Title order={1} fw={600}>{t('patient:patient-application-complete:title')}</Title>
          <Text size="md" c="gray.6">
            {t('patient:patient-application-complete:subtitle')}
          </Text>
        </div>
      </div>
    </div>
  )
}
