import {
  Anchor,
  Button, CopyButton, Text, Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { images } from '../../../shared/utils/public'
import { Path } from '../../../shared/enums'
import { EligibilityTestResponseStatus } from '../enum'
import EligibilityTestResponseResult from '../../../interfaces/eligibility-test/eligibility-test-result'
import { getPath } from '../../../shared/utils/demo'

interface Props {
  eligibilityTestResponseResult: EligibilityTestResponseResult
}

export default function EligibilityTestResult({ eligibilityTestResponseResult }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-center">
      {eligibilityTestResponseResult.status === EligibilityTestResponseStatus.Approved ? (
        <>
          <img
            className="md:h-65 h-35 object-contain my-5"
            src={images.checkmarkTestTube.src}
            alt={images.checkmarkTestTube.alt}
          />
          <div className="flex flex-col md:w-9/12 w-full gap-3">
            <Title fw={600}>{t('application:result:eligible-patient')}</Title>
            {eligibilityTestResponseResult.shortUrl ? (
              <>
                <Text size="md" c="gray.6">
                  {t('application:result:eligible-patient-info')}
                </Text>
                <CopyButton value={eligibilityTestResponseResult.shortUrl} timeout={5000}>
                  {({ copied, copy }) => (
                    <Button className="mt-4 sm:text-sm text-md sm:h-8 h-10" variant={copied ? 'filled' : 'outline'} color="violet" onClick={copy}>
                      {copied ? t('application:result:copied-link') : t('application:result:copy-link')}
                    </Button>
                  )}
                </CopyButton>
                <Anchor className="ml-auto" onClick={() => navigate(getPath(Path.ClinicalTrials))}>
                  {t('application:result:enroll-another-patient')}
                  {' '}
                  {'>'}
                </Anchor>
              </>
            ) : (
              <>
                <Text size="md" c="gray.6">
                  {t('application:result:authorized-patient')}
                </Text>
                <Button
                  className="mt-4 sm:text-sm text-md sm:h-8 h-10"
                  variant="outline"
                  onClick={() => navigate(getPath(Path.ClinicalTrials))}
                >
                  {t('application:result:enroll-another-patient')}
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <img
            className="md:h-65 h-35 object-contain my-5"
            src={images.clipboardWithCubes.src}
            alt={images.clipboardWithCubes.alt}
          />
          <div className="flex flex-col md:w-9/12 w-full gap-5">
            <Title order={1} fw={600}>{t('application:result:not-eligible-patient')}</Title>
            <Text size="md" c="gray.6">
              {t('application:result:not-eligible-patient-info')}
            </Text>
            <Button
              className="sm:text-sm text-md sm:h-8 h-10"
              variant="outline"
              onClick={() => navigate(getPath(Path.ClinicalTrials))}
            >
              {t('application:result:enroll-another-patient')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
