import { IconType } from 'react-icons'
import ToggleCardButton from './toggle-card-button'

interface Option<ModelSchema> {
  value: ModelSchema;
  label: string;
  icon?: IconType;
}

interface Props<ModelSchema> {
  options: Option<ModelSchema>[];
  select: string | null;
  onChange: (value: ModelSchema) => void;
}

function ToggleCardGroup<ModelSchema>({
  options, select, onChange,
} : Props<ModelSchema>) {
  return (
    <div className="flex gap-5 w-full">
      {options.map((option) => (
        <ToggleCardButton
          key={option.label}
          value={option.value}
          label={option.label}
          Icon={option.icon}
          checked={select === option.value}
          onChange={() => onChange(option.value)}
        />
      ))}
    </div>
  )
}

export default ToggleCardGroup
