import { Skeleton } from '@mantine/core'

export default function PatientClinicalTrialInvitationSkeleton() {
  return (
    <div className="flex flex-col gap-6 mx-2">
      <Skeleton width="100%" radius="xl" className="h-55 sm:h-30" />
      <Skeleton width="100%" radius="xl" className="h-55 sm:h-30" />
      <Skeleton width="100%" radius="xl" className="h-55 sm:h-30" />
    </div>
  )
}
