import { create } from 'zustand'
import { User } from '../../../models'
import { userSessionLocalStorageUtils } from '../utils'
import isTokenExpired from '../utils/is-token-expired'

interface UserState {
  loggedUser: User
  isUserAuthenticated: boolean
  actions: {
    setLoggedUser: (user: User) => void
  }
}

const getDefaultLoggedUser = () => {
  const userSession = userSessionLocalStorageUtils.getUserSession()

  if (!userSession) {
    return {
      id: '',
      name: '',
      email: '',
      phone: '',
      groups: [],
    }
  }

  return userSession.user
}

const getDefaultIsUserAuthenticated = () => {
  const userSession = userSessionLocalStorageUtils.getUserSession()
  if (!userSession) return false

  const expiredToken = isTokenExpired(userSession.accessToken)
  if (expiredToken) userSessionLocalStorageUtils.removeUserSession()

  return !expiredToken
}

const useAuthUserStore = create<UserState>((set) => ({
  loggedUser: getDefaultLoggedUser(),
  isUserAuthenticated: getDefaultIsUserAuthenticated(),
  actions: {
    setLoggedUser: (user: User) => {
      set({ loggedUser: user, isUserAuthenticated: true })
      userSessionLocalStorageUtils.updateUserSession(user)
    },
  },
}))

export const useAuthUserLoggedUser = () => useAuthUserStore((state) => state.loggedUser)
export const useAuthUserIsUserAuthenticated = () => useAuthUserStore((state) => state.isUserAuthenticated)
export const useAuthUserActions = () => useAuthUserStore((state) => state.actions)
