import { kyBase } from '../config/ky'
import { LoginRequest } from '../requests/auth'
import VerifyRequest from '../requests/auth/verify'
import { VerifyResponse } from '../responses/auth'

const AUTH_URL = 'auth'

const login = async (request: LoginRequest) => kyBase.post(`${AUTH_URL}/login/passwordless`, {
  json: request,
  headers: {
    Authorization: request.applicantToken,
  },
}).json()

const verify = async (request: VerifyRequest): Promise<VerifyResponse> => kyBase.post(`${AUTH_URL}/verify/passwordless`, {
  json: request,
  headers: {
    Authorization: request.applicantToken,
  },
}).json<VerifyResponse>()

export default {
  login,
  verify,
}
