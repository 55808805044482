import ErrorExpiredShortUrl from '../features/error/components/error-expired-short-url'
import ErrorFallback from '../features/error/components/error-fallback'
import { Page } from '../interfaces/page'
import { Path } from '../shared/enums'
import ClinicalTrialDetail from './clinical-trial-detail'
import ClinicalTrials from './clinical-trials'
import CreatePatient from './create-patient'
import HealthCareProfessionalApplication from './healthcare-professional-application'
import PatientApplicationComplete from './patient-application-complete'

const pages: Page[] = [
  {
    component: <ClinicalTrials />,
    path: Path.ClinicalTrials,
  },
  {
    component: <ClinicalTrialDetail />,
    path: Path.ClinicalTrialDetail,
  },
  {
    component: <HealthCareProfessionalApplication />,
    path: Path.HealthCareProfessionalApplication,
  },
  {
    component: <CreatePatient />,
    path: Path.CreatePatient,
  },
  {
    component: <PatientApplicationComplete />,
    path: Path.PatientApplicationComplete,
  },
  {
    component: <ErrorExpiredShortUrl />,
    path: Path.ExpiredShortUrl,
  },
  {
    component: <ErrorFallback />,
    path: Path.Error,
  },
]

export default pages
