export const ufOptions = [
  { value: 'ac', label: 'AC' },
  { value: 'al', label: 'AL' },
  { value: 'ap', label: 'AP' },
  { value: 'am', label: 'AM' },
  { value: 'ba', label: 'BA' },
  { value: 'ce', label: 'CE' },
  { value: 'df', label: 'DF' },
  { value: 'es', label: 'ES' },
  { value: 'go', label: 'GO' },
  { value: 'ma', label: 'MA' },
  { value: 'mt', label: 'MT' },
  { value: 'ms', label: 'MS' },
  { value: 'mg', label: 'MG' },
  { value: 'pa', label: 'PA' },
  { value: 'pb', label: 'PB' },
  { value: 'pr', label: 'PR' },
  { value: 'pe', label: 'PE' },
  { value: 'pi', label: 'PI' },
  { value: 'rj', label: 'RJ' },
  { value: 'rn', label: 'RN' },
  { value: 'rs', label: 'RS' },
  { value: 'ro', label: 'RO' },
  { value: 'rr', label: 'RR' },
  { value: 'sc', label: 'SC' },
  { value: 'sp', label: 'SP' },
  { value: 'se', label: 'SE' },
  { value: 'to', label: 'TO' },
]
