import { createTheme } from '@mantine/core'
import themeConfiguration from './theme-configuration'

const mantineTheme = createTheme({
  colors: {
    violet: [
      themeConfiguration.colors.violet[50],
      themeConfiguration.colors.violet[100],
      themeConfiguration.colors.violet[200],
      themeConfiguration.colors.violet[300],
      themeConfiguration.colors.violet[400],
      themeConfiguration.colors.violet[500],
      themeConfiguration.colors.violet[600],
      themeConfiguration.colors.violet[700],
      themeConfiguration.colors.violet[800],
      themeConfiguration.colors.violet[900],
    ],
    gray: [
      themeConfiguration.colors.gray[50],
      themeConfiguration.colors.gray[100],
      themeConfiguration.colors.gray[200],
      themeConfiguration.colors.gray[300],
      themeConfiguration.colors.gray[400],
      themeConfiguration.colors.gray[500],
      themeConfiguration.colors.gray[600],
      themeConfiguration.colors.gray[700],
      themeConfiguration.colors.gray[800],
      themeConfiguration.colors.gray[900],
    ],
    green: [
      themeConfiguration.colors.green[50],
      themeConfiguration.colors.green[100],
      themeConfiguration.colors.green[200],
      themeConfiguration.colors.green[300],
      themeConfiguration.colors.green[400],
      themeConfiguration.colors.green[500],
      themeConfiguration.colors.green[600],
      themeConfiguration.colors.green[700],
      themeConfiguration.colors.green[800],
      themeConfiguration.colors.green[900],
    ],
    red: [
      themeConfiguration.colors.red[50],
      themeConfiguration.colors.red[100],
      themeConfiguration.colors.red[200],
      themeConfiguration.colors.red[300],
      themeConfiguration.colors.red[400],
      themeConfiguration.colors.red[500],
      themeConfiguration.colors.red[600],
      themeConfiguration.colors.red[700],
      themeConfiguration.colors.red[800],
      themeConfiguration.colors.red[900],
    ],
    yellow: [
      themeConfiguration.colors.yellow[50],
      themeConfiguration.colors.yellow[100],
      themeConfiguration.colors.yellow[200],
      themeConfiguration.colors.yellow[300],
      themeConfiguration.colors.yellow[400],
      themeConfiguration.colors.yellow[500],
      themeConfiguration.colors.yellow[600],
      themeConfiguration.colors.yellow[700],
      themeConfiguration.colors.yellow[800],
      themeConfiguration.colors.yellow[900],
    ],
  },
  primaryColor: 'violet',
  primaryShade: 7,
  fontFamily: 'Inter',
  fontSizes: {
    xs: themeConfiguration.font.size.xs,
    sm: themeConfiguration.font.size.sm,
    md: themeConfiguration.font.size.md,
    lg: themeConfiguration.font.size.lg,
    xl: themeConfiguration.font.size.xl,
  },
  headings: {
    fontFamily: 'Inter',
    fontWeight: themeConfiguration.font.weight[700],
    sizes: {
      h1: {
        fontSize: themeConfiguration.font.size.h1,
        fontWeight: themeConfiguration.font.weight[700],
        lineHeight: themeConfiguration.font.lineHeight.h1,
      },
      h2: {
        fontSize: themeConfiguration.font.size.h2,
        fontWeight: themeConfiguration.font.weight[700],
        lineHeight: themeConfiguration.font.lineHeight.h2,
      },
      h3: {
        fontSize: themeConfiguration.font.size.h3,
        fontWeight: themeConfiguration.font.weight[700],
        lineHeight: themeConfiguration.font.lineHeight.h3,
      },
      h4: {
        fontSize: themeConfiguration.font.size.h4,
        fontWeight: themeConfiguration.font.weight[700],
        lineHeight: themeConfiguration.font.lineHeight.h4,
      },
      h5: {
        fontSize: themeConfiguration.font.size.h5,
        fontWeight: themeConfiguration.font.weight[700],
        lineHeight: themeConfiguration.font.lineHeight.h5,
      },
      h6: {
        fontSize: themeConfiguration.font.size.h6,
        fontWeight: themeConfiguration.font.weight[700],
        lineHeight: themeConfiguration.font.lineHeight.h6,
      },
    },
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
  components: {
    Text: {
      defaultProps: {
        size: 'sm',
      },
    },
  },
})

export default mantineTheme
