import { kyInstance } from '../config/ky'
import { GetUrlRequest } from '../requests/short-urls'
import { GetUrlResponse } from '../responses/short-urls'

const SHORT_URLS_BASE_URL = 'short-urls'

const getUrl = async (request: GetUrlRequest) => kyInstance
  .get(`${SHORT_URLS_BASE_URL}/${request.shortUrlId}`).json<GetUrlResponse>()

export default {
  getUrl,
}
