import { ReactElement, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import PhoneNumberInputForm from './phone-number-input-form'
import SMSCodeConfirmationForm from './sms-code-confirmation-form'
import authService from '../services/auth-service'
import { SignUpSession, UserSession } from '../../../interfaces/auth/sessions'
import { useAuthUserActions } from '../store/auth-user-store'
import { UserAuthStage } from '../enum'
import { PhoneNumberInputAttributes, VerifyAttributes } from '../../../interfaces/auth'
import { formatPhoneNumber } from '../../../shared/utils/formatters'
import { isInstanceOfUserSession } from '../utils'

interface Props {
  onLoginSuccess?: (userSession: UserSession) => void
  onSignUpInitiate?: (signUpSession: SignUpSession) => void
}

export default function UserAuth({ onLoginSuccess, onSignUpInitiate }: Props) {
  const { t } = useTranslation()

  const [authStage, setAuthStage] = useState<UserAuthStage>(UserAuthStage.phoneNumberInput)
  const [authPhone, setAuthPhone] = useState('')

  const { setLoggedUser } = useAuthUserActions()

  const sendVerificationCode = async (phoneAttributes: PhoneNumberInputAttributes) => {
    await authService.login({ phone: formatPhoneNumber(phoneAttributes.phone) })
    setAuthPhone(phoneAttributes.phone)
    setAuthStage(UserAuthStage.smsCodeConfirmation)
  }

  const handleCodeConfirmation = async (verifyAttributes: VerifyAttributes) => {
    const session = await authService.verify(verifyAttributes)

    if (isInstanceOfUserSession(session)) {
      setLoggedUser(session.user)
      if (onLoginSuccess) onLoginSuccess(session)
      return
    }
    if (onSignUpInitiate) {
      onSignUpInitiate(session)
      return
    }
    toast.error(t('messages:error:user-not-found'))
  }

  const stageComponentMap: { [key in UserAuthStage]: ReactElement } = {
    [UserAuthStage.phoneNumberInput]: <PhoneNumberInputForm onPhoneNumberInput={sendVerificationCode} />,
    [UserAuthStage.smsCodeConfirmation]: <SMSCodeConfirmationForm
      phone={authPhone}
      onSMSCodeConfirmationInput={handleCodeConfirmation}
      onResendVerificationCode={sendVerificationCode}
    />,
  }

  return (
    <div className="2xl:w-120 xl:w-120 lg:w-120 md:w-120 sm:mx-auto mx-2 sm:mt-5 mt-8">
      {stageComponentMap[authStage]}
    </div>
  )
}
