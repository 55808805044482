const images = {
  resconnLogo: {
    src: '/img/resconn-logo.svg',
    alt: 'Resconn Logo',
  },
  whiteResconnLogo: {
    src: '/img/white-resconn-logo.svg',
    alt: 'White Resconn Logo',
  },
  checkmarkTestTube: {
    src: '/img/checkmark-test-tube.svg',
    alt: 'Checkmarck Test Tube',
  },
  clipboardWithCubes: {
    src: '/img/clipboard-with-cubes.svg',
    alt: 'Clipboard With Cubes',
  },
  labChecklistWithFlasks: {
    src: '/img/lab-checklist-with-flasks.svg',
    alt: 'Lab Checklist With Flasks',
  },
}

export {
  images,
}
