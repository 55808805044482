/* eslint-disable no-else-return */
import { toast } from 'react-toastify'
import i18next from 'i18next'
import { jwtDecode } from 'jwt-decode'
import { api } from '../../../libs'
import i18n from '../../../config/i18n'
import AccessTokenPayload from '../../../interfaces/auth/access-token-payload'
import SignUpTokenPayload from '../../../interfaces/auth/sign-up-token-payload'
import {
  LoginAttributes, Session, SignUpSession, UserSession, VerifyAttributes,
} from '../../../interfaces/auth'
import { AuthToken } from '../../../interfaces/auth/auth-token'
import { isInstanceOfUserAcessToken, userSessionLocalStorageUtils } from '../utils'

const login = async (loginAttributes: LoginAttributes) => {
  try {
    await api.auth.login(loginAttributes)
  } catch (error: unknown) {
    toast.error(i18next.t('messages:error:incorrect-phone'))
    throw error
  }
}

const verify = async (verifyAttributes: VerifyAttributes): Promise<Session> => {
  let authToken: AuthToken

  try {
    authToken = await api.auth.verify(verifyAttributes)
  } catch (error: unknown) {
    toast.error(i18n.t('messages:error:incorrect-code'))
    throw error
  }

  if (isInstanceOfUserAcessToken(authToken)) {
    const { accessToken } = authToken

    const accessTokenPayload = jwtDecode<AccessTokenPayload>(accessToken)

    const userSession: UserSession = {
      accessToken,
      user: accessTokenPayload.user,
    }

    userSessionLocalStorageUtils.setUserSession(userSession)

    return userSession
  } else {
    const { signUpToken } = authToken

    const signUpTokenPayload = jwtDecode<SignUpTokenPayload>(signUpToken)

    const signUpSession: SignUpSession = {
      signUpToken,
      phone: signUpTokenPayload.phone,
    }

    return signUpSession
  }
}

const authService = {
  login,
  verify,
}

export default authService
