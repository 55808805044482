import { create } from 'zustand'
import applicantSessionLocalStorageUtils from '../utils/applicant-session-local-storage-utils'
import { Applicant } from '../../../interfaces/patient'

interface ApplicantState {
  applicant: Applicant
  isApplicantAuthenticated: boolean
  actions: {
    setApplicant: (applicant: Applicant) => void
  }
}

const getDefaultApplicantSession = () => {
  const applicantSession = applicantSessionLocalStorageUtils.getApplicantSession()

  if (!applicantSession) {
    return {
      id: '',
      healthcareProfessionalApplicationId: '',
    }
  }

  return applicantSession.applicant
}

const getDefaultIsApplicantAuthenticated = () => {
  const applicantSession = applicantSessionLocalStorageUtils.getApplicantSession()

  return applicantSession !== null
}

const useApplincatStore = create<ApplicantState>((set) => ({
  applicant: getDefaultApplicantSession(),
  isApplicantAuthenticated: getDefaultIsApplicantAuthenticated(),
  actions: {
    setApplicant: (applicant: Applicant) => {
      set({ applicant, isApplicantAuthenticated: true })
      applicantSessionLocalStorageUtils.updateApplicantSession(applicant)
    },
  },
}))

export const useApplicantStoreApplicant = () => useApplincatStore((state) => state.applicant)
export const useApplicantStoreIsApplicantAuthenticated = () => useApplincatStore((state) => state.isApplicantAuthenticated)
export const useApplicantStoreActions = () => useApplincatStore((state) => state.actions)
