import { api } from '../../../libs'
import { ResearchCenter } from '../../../models'

const fetchResearchCenters = async (): Promise<ResearchCenter[]> => {
  const getResearchCentersResponse = await api.researchCenters.getResearchCenters()
  return getResearchCentersResponse
}

const researchCenterService = {
  fetchResearchCenters,
}

export default researchCenterService
