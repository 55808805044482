import { TbCheck, TbMessageCircleQuestion } from 'react-icons/tb'
import { IoMdClose } from 'react-icons/io'
import { EligibilityResponseType } from '../../../shared/enums'

const getEligibilityResponseTypeIcon = (responseType: EligibilityResponseType) => {
  const icon = {
    [EligibilityResponseType.Yes]: TbCheck,
    [EligibilityResponseType.No]: IoMdClose,
    [EligibilityResponseType.DoNotKnow]: TbMessageCircleQuestion,
  }
  return icon[responseType]
}

export default getEligibilityResponseTypeIcon
