import { kyInstance } from '../config/ky'
import GetResearchCentersResponse from '../responses/research-centers/get-research-centers-response'

const RESEARCH_CENTERS_BASE_URL = 'research-centers'

const getResearchCenters = async () => kyInstance.get(RESEARCH_CENTERS_BASE_URL).json<GetResearchCentersResponse>()

export default {
  getResearchCenters,
}
