import { useQuery } from 'react-query'
import { ResearchCenter } from '../../../models'
import QueryParams from '../../../interfaces/queries/query-params'
import researchCenterService from '../services/research-center-service'

const researchCenterKeys = {
  all: [{ scope: 'researchCenter' }] as const,
  lists: () => [{ ...researchCenterKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...researchCenterKeys.all[0], entity: 'single', id }] as const,
}

export const useResearchCentersQuery = <ModelSchema = ResearchCenter[]>(params?: QueryParams<ResearchCenter[], ModelSchema>) => useQuery({
  queryKey: researchCenterKeys.lists(),
  queryFn: researchCenterService.fetchResearchCenters,
  select: params?.select,
})
