import { create } from 'zustand'
import { ClinicalTrialFilter } from '../../../interfaces/clinical-trial/clinical-trial-filter'

interface ClinicalTrialFilterState {
  filter: ClinicalTrialFilter;
  actions: {
    setResearchCenters: (researchCenterId: string | null) => void;
    setHealthcareSpecialties: (healthcareSpecialties: string[]) => void;
  }
}

const useClinicalTrialFilterStore = create<ClinicalTrialFilterState>((set) => ({
  filter: {
    researchCenterId: null,
    healthcareSpecialties: [],
  },
  actions: {
    setResearchCenters: (researchCenterId) => set((state) => ({ filter: { ...state.filter, researchCenterId } })),
    setHealthcareSpecialties: (healthcareSpecialties) => set((state) => ({ filter: { ...state.filter, healthcareSpecialties } })),
  },
}))

export const useClinicalTrialFilter = () => useClinicalTrialFilterStore((state) => state.filter)
export const useClinicalTrialFilterActions = () => useClinicalTrialFilterStore((state) => state.actions)
