import { kyBase } from '../config/ky'
import { SignUpHealthcareProfessionalRequest } from '../requests/healthcare-professionals'
import { SignUpHealthcareProfessionalResponse } from '../responses/healthcare-professionals'

const HEALTHCARE_PROFESSIONALS_BASE_URL = 'healthcare-professionals'

const signUpHealthcareProfessional = async (request: SignUpHealthcareProfessionalRequest, signUpToken: string) => kyBase
  .post(`${HEALTHCARE_PROFESSIONALS_BASE_URL}/sign-up`, {
    json: request,
    headers: {
      Authorization: signUpToken,
    },
  }).json<SignUpHealthcareProfessionalResponse>()

export default {
  signUpHealthcareProfessional,
}
