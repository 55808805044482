import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../enums'
import { getPath } from '../../utils/demo'

export default function NotFoundPage() {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(getPath(Path.ClinicalTrials))
  }, [navigate])

  return (
    <div />
  )
}
