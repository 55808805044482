import applicantSessionLocalStorageUtils from '../../../features/patient/utils/applicant-session-local-storage-utils'
import { kyInstance } from '../config/ky'
import {
  CreateHealthcareProfessionalApplicationRequest,
  GetHealthcareProfessionalApplicationDetailsRequest,
  ProcessHealthcareProfessionalApplicationAuthorizationRequest,
  SubmitApplicationEligibilityTestRequest,
} from '../requests/healthcare-professional-applications'
import {
  CreateHealthcareProfessionalApplicationResponse,
  GetHealthcareProfessionalApplicationDetailsResponse,
  ProcessHealthcareProfessionalApplicationAuthorizationResponse,
  SubmitApplicationEligibilityTestResponse,
} from '../responses/healthcare-professional-applications'

const HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL = 'healthcare-professional-applications'

const createHealthcareProfessionalApplication = async (request: CreateHealthcareProfessionalApplicationRequest) => kyInstance
  .post(HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL, {
    json: request,
  }).json<CreateHealthcareProfessionalApplicationResponse>()

const getHealthcareProfessionalApplicationDetails = async (request: GetHealthcareProfessionalApplicationDetailsRequest) => kyInstance
  .get(`${HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL}/${request.id}/details`, {
    hooks: {
      beforeRequest: [
        (kyRequest) => {
          const applicantSession = applicantSessionLocalStorageUtils.getApplicantSession()

          if (applicantSession) {
            kyRequest.headers.set('Authorization', applicantSession.applicantToken)
          }
        },
      ],
    },
  }).json<GetHealthcareProfessionalApplicationDetailsResponse>()

const submitApplicationEligibilityTest = async (request: SubmitApplicationEligibilityTestRequest) => kyInstance
  .post(`${HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL}/${request.healthcareProfessionalApplicationId}/eligibility-test-response`, {
    json: request,
  }).json<SubmitApplicationEligibilityTestResponse>()

const processHealthcareProfessionalApplicationAuthorization = async (request: ProcessHealthcareProfessionalApplicationAuthorizationRequest) => kyInstance
  .post(`${HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL}/${request.id}/authorize`, {
    json: request,
  }).json<ProcessHealthcareProfessionalApplicationAuthorizationResponse>()

export default {
  createHealthcareProfessionalApplication,
  submitApplicationEligibilityTest,
  getHealthcareProfessionalApplicationDetails,
  processHealthcareProfessionalApplicationAuthorization,
}
