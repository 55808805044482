import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import './App.css'
import { MantineProvider } from '@mantine/core'
import { QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { queryClient } from './config'
import Router from './router'
import mantineTheme from './theme/mantine-theme'
import 'react-toastify/dist/ReactToastify.css'
import { initializeClarity } from './config/clarity'

function App() {
  initializeClarity(uuidv4())

  return (
    <div className="App" id="app">
      <MantineProvider theme={mantineTheme} defaultColorScheme="light">
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <Router />
        </QueryClientProvider>
      </MantineProvider>
    </div>
  )
}

export default App
