import { HTTPError } from 'ky'
import { QueryCache, QueryClient } from 'react-query'
import i18n from 'i18next'
import makeError from '../features/error/helpers/make-error'
import ErrorType from '../features/error/enums/error-type'
import didUserLoggedout from '../shared/utils/user/did-user-logged-out'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const errorResponse = error as HTTPError

      if (didUserLoggedout(errorResponse)) return

      if (query.state.data !== undefined) {
        makeError({
          message: i18n.t('messages:error.unexpected'),
          type: ErrorType.Notification,
        })
      }

      makeError({
        message: i18n.t('messages:error.unexpected'),
        type: ErrorType.Fallback,
        stack: errorResponse.stack,
      })
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
      onError: (error) => {
        const errorResponse = error as HTTPError

        if (didUserLoggedout(errorResponse)) return

        makeError({
          message: i18n.t('messages:error.unexpected'),
          type: ErrorType.Notification,
        })
      },
    },
  },
})

export default queryClient
