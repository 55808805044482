import i18n from '../../../config/i18n'
import { EligibilityResponseType } from '../../../shared/enums'

const getEligibilityResponseTypeLabel = (responseType: EligibilityResponseType) => {
  const label = {
    [EligibilityResponseType.Yes]: `${i18n.t('common:yes')}`,
    [EligibilityResponseType.No]: `${i18n.t('common:no')}`,
    [EligibilityResponseType.DoNotKnow]: `${i18n.t('common:do-not-know')}`,
  }
  return label[responseType]
}

export default getEligibilityResponseTypeLabel
