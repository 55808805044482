import {
  Badge, Card, Text, Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { convert } from 'html-to-text'
import { useTranslation } from 'react-i18next'
import { ExtendedClinicalTrial } from '../../../models'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'

interface Props {
  clinicalTrial: ExtendedClinicalTrial
}

export default function ClinicalTrialCard({ clinicalTrial }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const isMobile = useMediaQuery('(max-width: 639px)')

  return (
    <Card
      className="flex flex-col gap-1 cursor-pointer"
      withBorder
      onClick={() => navigate(getPath(Path.ClinicalTrialDetail.replace(':id', clinicalTrial.id)))}
    >
      <Text fw={600} c="violet.7">{clinicalTrial.pathology.name.toUpperCase()}</Text>
      <Title order={4} fw={600} c="gray.7">{clinicalTrial.title}</Title>
      {clinicalTrial.subtitle && <Title order={5} fw={500} c="gray.7">{clinicalTrial.subtitle}</Title>}
      <Text className="text-wrap" fw={500} c="gray.5" lineClamp={isMobile ? 3 : 2} truncate="end">
        {convert(clinicalTrial.description)}
      </Text>
      <Badge
        className="mt-[2px]"
        color="violet"
        variant="light"
      >
        {t(`clinicalTrial:healthcare-specialty:${clinicalTrial.healthcareSpecialty}`)}
      </Badge>
    </Card>
  )
}
