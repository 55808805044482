import { Skeleton } from '@mantine/core'

export default function EligibilityTestFormSkeleton() {
  return (
    <div className="flex flex-col gap-5 2xl:w-250 xl:w-200 lg:w-140 md:w-140 sm:mx-auto mx-2 sm:mt-5 mt-8">
      <Skeleton width="100%" radius="xl" className="h-35 sm:h-20" />
      <Skeleton width="100%" radius="xl" className="h-55 sm:h-30" />
      <Skeleton width="100%" radius="xl" className="h-55 sm:h-30" />
      <Skeleton width="100%" radius="xl" className="h-55 sm:h-30" />
    </div>
  )
}
