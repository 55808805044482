/* eslint-disable react/no-danger */
import { Text } from '@mantine/core'
import { useFormContext } from 'react-hook-form'
import { EligibilityQuestion } from '../../../models'
import ToggleCardGroup from '../../../shared/components/toggle-card-button/toggle-card-group'
import { EligibilityResponseType } from '../../../shared/enums'
import EligibilityTestAttributes from '../../../interfaces/eligibility-test/eligibility-test-attributes'
import { getEligibilityResponseTypeIcon, getEligibilityResponseTypeLabel } from '../utils'

interface Props {
  index: number
  indexKey: number
  eligibilityQuestion: EligibilityQuestion
}

export default function EligibilityQuestionItem({ index, indexKey, eligibilityQuestion }: Props) {
  const { setValue, watch } = useFormContext<EligibilityTestAttributes>()

  const eligibilityQuestionResponse = watch(`eligibilityQuestionResponses.${indexKey}.response`)

  const handleEligibilityQuestionResponseSelection = (response: EligibilityResponseType) => {
    setValue(`eligibilityQuestionResponses.${indexKey}.eligibilityQuestionId`, eligibilityQuestion.id)
    setValue(`eligibilityQuestionResponses.${indexKey}.response`, response)
  }

  const eligibilityQuestionAvailableResponses = eligibilityQuestion.availableResponses.map((availableResponse) => ({
    value: availableResponse,
    label: getEligibilityResponseTypeLabel(availableResponse),
    icon: getEligibilityResponseTypeIcon(availableResponse),
  }))

  return (
    <div className="flex flex-col gap-3">
      <Text size="md" c="gray.8">
        {index + 1}
        .
        {' '}
        <span dangerouslySetInnerHTML={{ __html: eligibilityQuestion.question }} />
      </Text>
      <ToggleCardGroup
        key={eligibilityQuestion.id}
        options={eligibilityQuestionAvailableResponses}
        select={eligibilityQuestionResponse}
        onChange={handleEligibilityQuestionResponseSelection}
      />
    </div>
  )
}
