import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ShortUrl } from '../../../models'
import { Path } from '../../../shared/enums'
import shortUrlService from '../services/short-url-service'
import { ApplicantSession } from '../../../interfaces/patient'
import { useApplicantStoreActions } from '../../patient/store/applicant-store'
import getErrorResponse from '../../error/helpers/get-error-respose'
import { getPath } from '../../../shared/utils/demo'

interface GetUrlResponse {
  shortUrl: ShortUrl
  applicantSession: ApplicantSession
}

export default function ShortUrlRedirect() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { setApplicant } = useApplicantStoreActions()

  useEffect(() => {
    const redirectToPage = async () => {
      if (!id) return navigate(getPath(Path.ClinicalTrials))

      let getUrlResponse: GetUrlResponse
      try {
        getUrlResponse = await shortUrlService.getUrl(id)
      } catch (error: any) {
        const response = await getErrorResponse(error)
        if (response?.code === 'expired_short_url') {
          return navigate(getPath(Path.ExpiredShortUrl))
        }
        return navigate(getPath(Path.ClinicalTrials))
      }

      setApplicant(getUrlResponse.applicantSession.applicant)

      const relativePath = new URL(getUrlResponse.shortUrl.url).pathname
      return navigate(getPath(relativePath))
    }
    redirectToPage()
  }, [id, navigate, setApplicant])

  return (
    <div />
  )
}
