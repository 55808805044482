import ky from 'ky'
import { userSessionLocalStorageUtils } from '../../../features/auth/utils'

const kyBase = ky.create({ prefixUrl: `${import.meta.env.VITE_SERVER_BASE_URL || ''}/v1` })

const kyInstance = kyBase.extend({
  prefixUrl: `${import.meta.env.VITE_SERVER_BASE_URL || ''}/v1`,
  hooks: {
    beforeRequest: [
      (request) => {
        const userSession = userSessionLocalStorageUtils.getUserSession()

        if (userSession) {
          request.headers.set('Authorization', userSession.accessToken)
        }
      },
    ],
  },
})

export { kyBase, kyInstance }
