import { Skeleton } from '@mantine/core'

export default function ClinicalTrialSkeleton() {
  return (
    <div className="flex flex-col w-11/12 md:min-h-[calc(100vh-200px)] h-[calc(100vh-200px)] mx-auto my-4 gap-8 overflow-y-hidden">
      <Skeleton height="55%" width="100%" radius="xl" />
      <Skeleton height="35%" width="100%" radius="xl" />
    </div>
  )
}
