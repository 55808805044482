import { Applicant, ApplicantSession } from '../../../interfaces/patient'
import { APPLICANT_SESSION_KEY } from '../../../shared/utils/constants'

const getApplicantSession = (): ApplicantSession | null => {
  const localStorageApplicantSession = localStorage.getItem(APPLICANT_SESSION_KEY)

  if (!localStorageApplicantSession) return null

  const applicantSession = JSON.parse(localStorageApplicantSession) as ApplicantSession

  return applicantSession
}

const setApplicantSession = (applicantSession: ApplicantSession) => {
  localStorage.setItem(APPLICANT_SESSION_KEY, JSON.stringify(applicantSession))
}

const updateApplicantSession = (applicant: Applicant) => {
  const applicantSession = getApplicantSession()

  if (!applicantSession) return

  const updatedApplicantSession = {
    applicantToken: applicantSession.applicantToken,
    applicant,
  }

  setApplicantSession(updatedApplicantSession)
}

const applicantSessionLocalStorageUtils = {
  getApplicantSession,
  setApplicantSession,
  updateApplicantSession,
}

export default applicantSessionLocalStorageUtils
