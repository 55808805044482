import { useQuery } from 'react-query'
import clinicalTrialService from '../services/clinical-trial-service'
import { ExtendedClinicalTrial } from '../../../models'
import QueryParams from '../../../interfaces/queries/query-params'
import { useClinicalTrialsSearchTerm } from '../store/clinical-trials-search-term-store'
import searchClinicalTrialsUtils from '../utils/search-clinical-trials-utils'
import searchHelper from '../../../shared/helpers/search-helper'
import { useClinicalTrialFilter } from '../store/clinical-trials-filter-store'
import getFilteredClinicalTrials from '../utils/clinical-trial-filter-utils'

const clinicalTrialKeys = {
  all: [{ scope: 'clinicalTrials' }] as const,
  lists: () => [{ ...clinicalTrialKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...clinicalTrialKeys.all[0], entity: 'single', id }] as const,
}

export const useClinicalTrialsQuery = <ModelSchema = ExtendedClinicalTrial[]>(params?: QueryParams<ExtendedClinicalTrial[], ModelSchema>) => useQuery({
  queryKey: clinicalTrialKeys.lists(),
  queryFn: clinicalTrialService.fetchClinicalTrials,
  select: params?.select,
})

export const useFilteredClinicalTrialsQuery = () => {
  const searchTerm = useClinicalTrialsSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = searchClinicalTrialsUtils

  const filter = useClinicalTrialFilter()
  return useClinicalTrialsQuery({
    select: (queriedResources) => getFilteredClinicalTrials(
      searchHelper.getFilteredDataBySearchTerm(queriedResources, partialMatchKeys, fullMatchKeys, searchTerm),
      filter,
    ),
  })
}

export const useClinicalTrialQuery = (id?: string) => useQuery({
  queryKey: clinicalTrialKeys.single(id!),
  queryFn: () => clinicalTrialService.fetchClinicalTrial({ id: id! }),
  enabled: !!id,
})
