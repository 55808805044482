import { Button, Text, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { images } from '../../../shared/utils/public'
import PatientClinicalTrialInvitationSkeleton from './patient-clinical-trial-invitation-skeletion'
import { HealthcareProfessionalApplicationDetails } from '../../../interfaces/healthcare-professional-application'

interface Props {
  initPatientAuth: () => void
  healthcareProfessionalApplicationDetails: HealthcareProfessionalApplicationDetails | undefined
  isLoading: boolean
}

export default function PatientClinicalTrialInvitation({ initPatientAuth, healthcareProfessionalApplicationDetails, isLoading }: Props) {
  const { t } = useTranslation()

  if (isLoading || !healthcareProfessionalApplicationDetails) {
    return <PatientClinicalTrialInvitationSkeleton />
  }

  return (
    <div className="flex flex-col items-center gap-7">
      <img
        className="md:h-60 h-35 object-contain mt-5"
        src={images.labChecklistWithFlasks.src}
        alt={images.labChecklistWithFlasks.alt}
      />
      <div className="flex flex-col gap-3">
        <Title order={1} fw={600}>
          {t('patient:clinical-trial-invitation:title', {
            healthcareProfessionalName: healthcareProfessionalApplicationDetails.healthcareProfessional.name,
          })}
        </Title>
        <Text size="md" c="gray.6">
          {t('patient:clinical-trial-invitation:subtitle', {
            clinicalTrialName: healthcareProfessionalApplicationDetails.clinicalTrial.title,
          })}
        </Text>
      </div>
      <Button size="md" onClick={initPatientAuth}>{t('patient:clinical-trial-invitation:button')}</Button>
    </div>
  )
}
