import { PatientAttributes } from '../../../interfaces/patient'
import { formatPhoneNumber, formatTaxId } from '../../../shared/utils/formatters'

const patientAttributesToSignUpPatientAttributes = (patientAttributes: PatientAttributes, applicantId: string) => ({
  dateOfBirth: patientAttributes.dateOfBirth,
  taxId: formatTaxId(patientAttributes.taxId),
  gender: patientAttributes.gender,
  healthcareProfessionalApplicantId: applicantId,
  user: {
    name: patientAttributes.name,
    phone: formatPhoneNumber(patientAttributes.phone),
    email: patientAttributes.email,
  },
})

const patientAttributesUtils = {
  patientAttributesToSignUpPatientAttributes,
}

export default patientAttributesUtils
