import {
  Button,
  Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { images } from '../../../shared/utils/public'
import { useProcessHealthcareProfessionalApplicationAuthorization } from '../../healthcare-professional/queries'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'

interface Props {
  healthcareProfessionaApplicationlId: string
}

export default function PatientConfirmation({ healthcareProfessionaApplicationlId }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { mutate, isLoading } = useProcessHealthcareProfessionalApplicationAuthorization(healthcareProfessionaApplicationlId)

  const handleHealthcareProfessionalApplicationAuthorization = () => {
    mutate()
    navigate(getPath(Path.PatientApplicationComplete))
  }

  return (
    <div className="flex flex-col items-center">
      <img
        className="md:h-65 h-35 object-contain my-5"
        src={images.checkmarkTestTube.src}
        alt={images.checkmarkTestTube.alt}
      />
      <div className="flex flex-col 2xl:w-9/12 md:w-10/12 w-full gap-10">
        <Title order={1} fw={600}>{t('patient:patient-authorization:title')}</Title>
        <div className="flex gap-5 items-center justify-center w-full">
          <Button
            size="md"
            onClick={handleHealthcareProfessionalApplicationAuthorization}
            loading={isLoading}
          >
            {t('patient:patient-authorization:confirm')}
          </Button>
          <Button
            size="md"
            variant="outline"
            onClick={() => navigate(getPath(Path.ClinicalTrials))}
          >
            {t('patient:patient-authorization:refuse')}
          </Button>
        </div>
      </div>
    </div>
  )
}
