import i18next from 'i18next'
import { HealthcareSpecialty } from '../../../shared/enums'

export const healthcareSpecialtyOptions = [
  { value: HealthcareSpecialty.Cardiology, label: i18next.t('clinicalTrial:healthcare-specialty:cardiology') },
  { value: HealthcareSpecialty.Oncology, label: i18next.t('clinicalTrial:healthcare-specialty:oncology') },
  { value: HealthcareSpecialty.Dermatology, label: i18next.t('clinicalTrial:healthcare-specialty:dermatology') },
  { value: HealthcareSpecialty.Neurology, label: i18next.t('clinicalTrial:healthcare-specialty:neurology') },
  { value: HealthcareSpecialty.Pediatrics, label: i18next.t('clinicalTrial:healthcare-specialty:pediatrics') },
  { value: HealthcareSpecialty.Orthopedics, label: i18next.t('clinicalTrial:healthcare-specialty:orthopedics') },
  { value: HealthcareSpecialty.Gastroenterology, label: i18next.t('clinicalTrial:healthcare-specialty:gastroenterology') },
  { value: HealthcareSpecialty.Psychiatry, label: i18next.t('clinicalTrial:healthcare-specialty:psychiatry') },
  { value: HealthcareSpecialty.Ophthalmology, label: i18next.t('clinicalTrial:healthcare-specialty:ophthalmology') },
  { value: HealthcareSpecialty.Radiology, label: i18next.t('clinicalTrial:healthcare-specialty:radiology') },
  { value: HealthcareSpecialty.Endocrinology, label: i18next.t('clinicalTrial:healthcare-specialty:endocrinology') },
  { value: HealthcareSpecialty.Urology, label: i18next.t('clinicalTrial:healthcare-specialty:urology') },
  { value: HealthcareSpecialty.Hematology, label: i18next.t('clinicalTrial:healthcare-specialty:hematology') },
  { value: HealthcareSpecialty.Pulmonology, label: i18next.t('clinicalTrial:healthcare-specialty:pulmonology') },
]
