import { toast } from 'react-toastify'
import ErrorType from '../enums/error-type'
import AppError from '../interfaces/error-logger'
import { Path } from '../../../shared/enums'

const makeError = (error: AppError) => {
  switch (error.type) {
    case ErrorType.Fallback:
      window.location.href = Path.Error
      break

    case ErrorType.Notification:
    default:
      toast.error(error.message)
      break
  }
}

export default makeError
