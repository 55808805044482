import { create } from 'zustand'

interface ClinicalTrialsSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const useClinicalTrialsSearchTermStore = create<ClinicalTrialsSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const useClinicalTrialsSearchTerm = () => useClinicalTrialsSearchTermStore((state) => state.searchTerm)
export const useClinicalTrialsSearchTermActions = () => useClinicalTrialsSearchTermStore((state) => state.actions)
