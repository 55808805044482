import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import PatientClinicalTrialInvitation from '../features/patient/components/patient-clinical-trial-invitation'
import SMSCodeConfirmationPatientForm from '../features/patient/components/sms-code-confirmation-patient-form'
import { useApplicantStoreApplicant, useApplicantStoreIsApplicantAuthenticated } from '../features/patient/store/applicant-store'
import { Path } from '../shared/enums'
import PatientForm from '../features/patient/components/patient-form'
import { useHealthcareProfessionalApplicationDetailsQuery } from '../features/healthcare-professional/queries'
import patientService from '../features/patient/services/patient-service'
import { SignUpSession } from '../interfaces/auth'
import { useAuthUserIsUserAuthenticated, useAuthUserLoggedUser } from '../features/auth/store/auth-user-store'
import { Group } from '../features/auth/enum'
import PatientConfirmation from '../features/patient/components/patient-confirmation'
import { getPath } from '../shared/utils/demo'

export default function CreatePatient() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [signUpSession, setSignUpSession] = useState<SignUpSession | null>(null)
  const isUserAuthenticated = useAuthUserIsUserAuthenticated()
  const user = useAuthUserLoggedUser()

  const isApplicantAuthenticated = useApplicantStoreIsApplicantAuthenticated()
  const applicant = useApplicantStoreApplicant()

  const { data: healthcareProfessionalApplicationDetails, isLoading } = useHealthcareProfessionalApplicationDetailsQuery(applicant.healthcareProfessionalApplicationId)

  useEffect(() => {
    if (isUserAuthenticated && user.groups.includes(Group.Patient)) setCurrentStep(3)
    if (isUserAuthenticated && user.groups.includes(Group.HealthcareProfessional)) {
      navigate(getPath(Path.ClinicalTrials))
      toast.error(t('messages:error:default'))
      return
    }
    if (!isApplicantAuthenticated) navigate(getPath(Path.ExpiredShortUrl))
  }, [isApplicantAuthenticated, isUserAuthenticated, user, navigate, t])

  const initPatientAuth = async () => {
    setCurrentStep(1)
    await patientService.loginPatient()
  }

  const handleSignUpInitiate = async (session: SignUpSession) => {
    setSignUpSession(session)
    setCurrentStep(2)
  }

  return (
    <div className="sm:w-8/12 flex flex-col sm:mx-auto mx-6 my-4">
      {currentStep === 0 && (
        <PatientClinicalTrialInvitation
          initPatientAuth={initPatientAuth}
          healthcareProfessionalApplicationDetails={healthcareProfessionalApplicationDetails}
          isLoading={isLoading}
        />
      )}
      {currentStep === 1 && (
        <SMSCodeConfirmationPatientForm
          phone={healthcareProfessionalApplicationDetails?.healthcareProfessionalApplicant.phone!}
          onSignUpInitiate={handleSignUpInitiate}
        />
      )}
      {currentStep === 2 && (
        <PatientForm
          applicant={applicant}
          signUpSession={signUpSession}
        />
      )}
      {currentStep === 3 && (
        <PatientConfirmation healthcareProfessionaApplicationlId={applicant.healthcareProfessionalApplicationId} />
      )}
    </div>
  )
}
