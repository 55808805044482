import { MatchKey } from '../../interfaces/queries/match-key'

// eslint-disable-next-line max-len
const isSearchable = (value: any) => (['string', 'number'].includes(typeof value) || (Array.isArray(value) && value.every((item) => ['string', 'number'].includes(typeof item))))

const removeAccentMarks = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const getFilteredDataBySearchTerm = <ModelSchema extends object>(
  data: ModelSchema[], partialMatchKeys: MatchKey<ModelSchema>[], fullMatchKeys: MatchKey<ModelSchema>[], searchTerm: string) => {
  if (searchTerm === '' || searchTerm === undefined || searchTerm === null) return data

  // The MatchKey type generated a big amount of error types, due to TS lack of context
  // We parsed back the values to string[] to simplify the type checking
  const stringParsedPartialMatchKeys = partialMatchKeys as string[]
  const stringParsedFullMatchKeys = fullMatchKeys as string[]

  const standardizedSearchTerm = removeAccentMarks(searchTerm.toLocaleLowerCase().trim())

  const keys = stringParsedPartialMatchKeys.concat(stringParsedFullMatchKeys)

  return data.filter((record) => keys.some((key) => {
    const nestedKeys = key.split('.')
    const nestedValue = nestedKeys.reduce((obj, nestedKey) => obj && obj[nestedKey], record as Record<string, any>)

    if (!isSearchable(nestedValue)) return false

    const standardizedRecordValue = Array.isArray(nestedValue)
      ? nestedValue.map((item) => removeAccentMarks(item.toString().toLocaleLowerCase().trim()))
      : [removeAccentMarks(nestedValue.toString().toLocaleLowerCase().trim())]

    if (stringParsedPartialMatchKeys.includes(key)) {
      return standardizedRecordValue.some((value) => value.includes(standardizedSearchTerm))
    }

    return standardizedRecordValue.includes(standardizedSearchTerm)
  }))
}

const searchHelper = {
  getFilteredDataBySearchTerm,
}

export default searchHelper
