import { MatchKey } from '../../../interfaces/queries/match-key'
import { ExtendedClinicalTrial } from '../../../models'

const partialMatchKeys : MatchKey<ExtendedClinicalTrial>[] = ['title', 'pathology.name', 'pathology.aliases']
const fullMatchKeys : MatchKey<ExtendedClinicalTrial>[] = []
const indexKey = 'id' as keyof ExtendedClinicalTrial

const searchClinicalTrialsUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default searchClinicalTrialsUtils
