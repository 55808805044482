import { jwtDecode, JwtPayload } from 'jwt-decode'

const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = jwtDecode(token) as JwtPayload | null

    if (!decoded || typeof decoded.exp !== 'number') {
      return true
    }

    const currentTime = Math.floor(Date.now() / 1000)

    return currentTime > decoded.exp
  } catch (error) {
    return true
  }
}

export default isTokenExpired
