import {
  Anchor, Button, Text, Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { images } from '../../../shared/utils/public'
import resetErrorBoundary from '../helpers/reset-error-bondary'

export default function ErrorFallback() {
  const { t } = useTranslation()

  const handleResetErrorBoundary = () => {
    resetErrorBoundary()
  }

  return (
    <div className="2xl:w-5/12 md:w-8/12 sm:w-7/12 flex flex-col gap-5 sm:mx-auto mx-6 my-4">
      <div className="flex flex-col items-center">
        <img
          className="md:h-65 h-35 object-contain my-5"
          src={images.clipboardWithCubes.src}
          alt={images.clipboardWithCubes.alt}
        />
        <div className="flex flex-col gap-5">
          <Title order={1} fw={600}>{t('common:error-page:title')}</Title>
          <Button className="sm:text-sm text-md sm:h-8 h-10" variant="outline" onClick={handleResetErrorBoundary}>{t('common:return')}</Button>
          <div className="flex justify-center gap-1">
            <Text size="md" c="gray.6">
              {t('common:error-page:subtitle')}
            </Text>
            <Anchor href="https://api.whatsapp.com/send?phone=5531995901901" target="_blank" className="text-primary">
              {t('common:error-page:subtitle-span')}
            </Anchor>
          </div>
        </div>
      </div>
    </div>
  )
}
