import { api } from '../../../libs'
import GetClinicalTrialRequest from '../../../libs/api/requests/clinical-trials/get-clinical-trial-request'
import { ExtendedClinicalTrial } from '../../../models'

const fetchClinicalTrials = async (): Promise<ExtendedClinicalTrial[]> => {
  const getClinicalTrialsResponse = await api.clinicalTrial.getClinicalTrials()
  return getClinicalTrialsResponse
}

const fetchClinicalTrial = async (getClinicalTrialAttributes: GetClinicalTrialRequest): Promise<ExtendedClinicalTrial> => {
  const getClinicalTrialResponse = await api.clinicalTrial.getClinicalTrial(getClinicalTrialAttributes)
  return getClinicalTrialResponse
}

const clinicalTrialService = {
  fetchClinicalTrials,
  fetchClinicalTrial,
}

export default clinicalTrialService
