import { toast } from 'react-toastify'
import { jwtDecode } from 'jwt-decode'
import { api } from '../../../libs'
import {
  AccessToken, AuthToken, Session, SignUpSession, UserSession,
} from '../../../interfaces/auth'
import { isInstanceOfUserAcessToken, userSessionLocalStorageUtils } from '../../auth/utils'
import VerifyPatientAttributes from '../../../interfaces/patient/patient-verify-attributes'
import AccessTokenPayload from '../../../interfaces/auth/access-token-payload'
import { SignUpPatientAttributes } from '../../../interfaces/patient'
import i18n from '../../../config/i18n'
import SignUpTokenPayload from '../../../interfaces/auth/sign-up-token-payload'
import applicantSessionLocalStorageUtils from '../utils/applicant-session-local-storage-utils'
import { Path } from '../../../shared/enums'
import getErrorResponse from '../../error/helpers/get-error-respose'

const loginPatient = async () => {
  const applicantSession = applicantSessionLocalStorageUtils.getApplicantSession()
  if (!applicantSession) {
    window.location.href = Path.ClinicalTrials
    return
  }

  try {
    await api.auth.login({ applicantToken: applicantSession.applicantToken })
  } catch (error: unknown) {
    toast.error(i18n.t('messages:error:default'))
    throw error
  }
}

const verifyPatient = async (verifyPatientAttributes: VerifyPatientAttributes): Promise<Session | null> => {
  const applicantSession = applicantSessionLocalStorageUtils.getApplicantSession()
  if (!applicantSession) return null

  let authToken: AuthToken

  try {
    authToken = await api.auth.verify({ ...verifyPatientAttributes, applicantToken: applicantSession.applicantToken })
  } catch (error: unknown) {
    toast.error(i18n.t('messages:error:incorrect-code'))
    throw error
  }

  if (isInstanceOfUserAcessToken(authToken)) {
    const { accessToken } = authToken

    const accessTokenPayload = jwtDecode<AccessTokenPayload>(accessToken)

    const userSession: UserSession = {
      accessToken,
      user: accessTokenPayload.user,
    }

    userSessionLocalStorageUtils.setUserSession(userSession)

    return userSession
  }
  const { signUpToken } = authToken

  const signUpTokenPayload = jwtDecode<SignUpTokenPayload>(signUpToken)

  const signUpSession: SignUpSession = {
    signUpToken,
    phone: signUpTokenPayload.phone,
  }

  return signUpSession
}

const signUpPatient = async (
  signUpPatientAttributes: SignUpPatientAttributes,
  signUpToken: string,
) => {
  let signUpResponse: AccessToken

  try {
    signUpResponse = await api.patients.signUpPatient(signUpPatientAttributes, signUpToken)
  } catch (error: any) {
    const response = await getErrorResponse(error)
    if (response?.code === 'patient_mismatch_error') {
      toast.error(i18n.t('messages:error:mismatch-patient-form'))
      throw error
    }

    toast.error(i18n.t('messages:error:default'))
    throw error
  }
  const { accessToken } = signUpResponse

  const accessTokenPayload = jwtDecode<AccessTokenPayload>(accessToken)

  const userSession: UserSession = {
    accessToken,
    user: accessTokenPayload.user,
  }

  userSessionLocalStorageUtils.setUserSession(userSession)

  return userSession
}

const patientService = {
  loginPatient,
  verifyPatient,
  signUpPatient,
}

export default patientService
