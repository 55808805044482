const getErrorResponse = async (error: any) => {
  try {
    const response = await error.response.json()
    return response
  } catch {
    return null
  }
}

export default getErrorResponse
