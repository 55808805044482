import { jwtDecode } from 'jwt-decode'
import { api } from '../../../libs'
import { ApplicantSession, ApplicantTokenPayload } from '../../../interfaces/patient'
import applicantSessionLocalStorageUtils from '../../patient/utils/applicant-session-local-storage-utils'

const getUrl = async (id: string) => {
  const shortUrl = await api.shortUrls.getUrl({ shortUrlId: id })

  const { applicantToken } = shortUrl

  const applicantTokenPayload = jwtDecode<ApplicantTokenPayload>(applicantToken)

  const applicantSession: ApplicantSession = {
    applicantToken,
    applicant: applicantTokenPayload.applicant,
  }

  applicantSessionLocalStorageUtils.setApplicantSession(applicantSession)

  return { shortUrl, applicantSession }
}

const shortUrlService = {
  getUrl,
}

export default shortUrlService
