import { HTTPError } from 'ky'
import { toast } from 'react-toastify'
import i18n from '../../../config/i18n'
import { USER_SESSION_KEY } from '../constants'
import { Path } from '../../enums'
import { getPath } from '../demo'

type StatusCodeList = (number | undefined)[]

const logoutStatusCodes: StatusCodeList = [401, 403]

const didUserLoggedout = (error: HTTPError) => {
  if (!error.response) return false
  const statusCode = error.response.status

  if (logoutStatusCodes.includes(statusCode)) {
    localStorage.removeItem(USER_SESSION_KEY)
    window.location.href = getPath(Path.ClinicalTrials)
    toast.error(i18n.t('messages:error.unauthorized'))
    return true
  }
  return false
}

export default didUserLoggedout
