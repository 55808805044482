import { useFilteredClinicalTrialsQuery } from '../queries/clinical-trial-queries'
import ClinicalTrialCard from './clinical-trial-card'
import ClinicalTrialListSkeleton from './clinical-trial-list-skeleton'

export default function ClinicalTrialList() {
  const {
    data: clinicalTrials,
    isLoading,
  } = useFilteredClinicalTrialsQuery()

  if (isLoading) {
    return <ClinicalTrialListSkeleton />
  }

  return (
    <div className="flex flex-col gap-6 sm:mx-10 mx-1">
      {clinicalTrials?.map((clinicalTrial) => (
        <ClinicalTrialCard key={clinicalTrial.id} clinicalTrial={clinicalTrial} />
      ))}
    </div>
  )
}
