import { kyInstance } from '../config/ky'
import { GetClinicalTrialRequest } from '../requests/clinical-trials'
import { GetClinicalTrialResponse, GetClinicalTrialsResponse } from '../responses/clinical-trials'

const CLINICAL_TRIALS_BASE_URL = 'clinical-trials'

const getClinicalTrials = async () => kyInstance.get(CLINICAL_TRIALS_BASE_URL).json<GetClinicalTrialsResponse>()

const getClinicalTrial = async (request: GetClinicalTrialRequest) => kyInstance.get(`${CLINICAL_TRIALS_BASE_URL}/${request.id}`).json<GetClinicalTrialResponse>()

export default {
  getClinicalTrials,
  getClinicalTrial,
}
