import { Council } from '../../../shared/enums'

export const councilOptions = [
  { value: Council.Crm, label: 'Conselho Regional de Medicina (CRM)' },
  { value: Council.Crn, label: 'Conselho Regional de Nutrição (CRN)' },
  { value: Council.Crf, label: 'Conselho Regional de Farmácia (CRF)' },
  { value: Council.Crp, label: 'Conselho Regional de Psicologia (CRP)' },
  { value: Council.Cro, label: 'Conselho Regional de Odontologia (CRO)' },
  { value: Council.Cras, label: 'Conselho Regional de Assistência Social (CRAS)' },
  { value: Council.Crfa, label: 'Conselho Regional de Fonoaudiologia (CRFA)' },
  { value: Council.Coren, label: 'Conselho Regional de Enfermagem (COREN)' },
  { value: Council.Crefito, label: 'Conselho Regional de Fisioterapia e Terapia Ocupacional (CREFITO)' },
]
