import {
  Button, Input, Text, Title,
} from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IMaskInput } from 'react-imask'
import { phoneValidationRegex } from '../../../shared/utils/regex'
import { PhoneNumberInputAttributes } from '../../../interfaces/auth'

interface Props {
  onPhoneNumberInput: (phoneAttributes: PhoneNumberInputAttributes) => void
}

export default function PhoneNumberInputForm({ onPhoneNumberInput }: Props) {
  const { t } = useTranslation()

  const {
    handleSubmit, control, formState: { errors, isValid },
  } = useForm<PhoneNumberInputAttributes>({ mode: 'onTouched' })

  return (
    <div className="flex flex-col">
      <Title order={1} fw={600} c="gray.9">{t('healthcareProfessional:auth:enter-phone')}</Title>
      <Text className="mt-2" c="gray.6">{t('healthcareProfessional:auth:phone-form-instructions')}</Text>
      <form
        className="mt-5"
        onSubmit={handleSubmit(onPhoneNumberInput)}
      >
        <Controller
          name="phone"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
            validate: {
              validPhone: (phone) => phoneValidationRegex.test(phone) || t('common:form-error:invalid-phone'),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <Input.Wrapper label={t('common:cellphone')} required error={errors.phone && errors.phone.message}>
              <Input
                placeholder={t('common:cellphone')}
                required
                component={IMaskInput}
                mask="(00) 00000-0000"
                inputRef={ref}
                type="tel"
                {...field}
              />
            </Input.Wrapper>
          )}
        />
        <Button
          fullWidth
          color="violet"
          disabled={!isValid}
          className="mt-8 sm:text-sm text-md sm:h-8 h-10"
          type="submit"
        >
          {t('common:confirm')}
        </Button>
      </form>
    </div>
  )
}
