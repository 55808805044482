import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SignUpSession, UserSession, VerifyAttributes } from '../../../interfaces/auth'
import SMSCodeConfirmationForm from '../../auth/components/sms-code-confirmation-form'
import { isInstanceOfUserSession } from '../../auth/utils'
import { useAuthUserActions } from '../../auth/store/auth-user-store'
import patientService from '../services/patient-service'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'

interface Props {
  phone: string
  onSignUpInitiate?: (session: SignUpSession) => void
  onLoginSuccess?: (session: UserSession) => void
}

export default function SMSCodeConfirmationPatientForm({ phone, onSignUpInitiate, onLoginSuccess }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setLoggedUser } = useAuthUserActions()

  const resendVerificationCode = async () => {
    await patientService.loginPatient()
  }

  const handleCodeConfirmation = async (verifyAttributes: VerifyAttributes) => {
    const session = await patientService.verifyPatient({ code: verifyAttributes.code })
    if (!session) {
      navigate(getPath(Path.ClinicalTrials))
      return
    }
    if (isInstanceOfUserSession(session)) {
      setLoggedUser(session.user)
      if (onLoginSuccess) onLoginSuccess(session)
      return
    }

    if (onSignUpInitiate) {
      onSignUpInitiate(session)
      return
    }

    toast.error(t('messages:error:user-not-found'))
  }

  return (
    <div className="flex flex-col 2xl:w-140 xl:w-140 lg:w-140 md:w-140 sm:mx-auto mx-2 sm:mt-5 mt-8">
      <SMSCodeConfirmationForm
        phone={phone}
        onResendVerificationCode={resendVerificationCode}
        onSMSCodeConfirmationInput={handleCodeConfirmation}
      />
    </div>
  )
}
