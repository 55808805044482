import { UserSession } from '../../../interfaces/auth/sessions'
import { User } from '../../../models'
import { USER_SESSION_KEY } from '../../../shared/utils/constants'

const getUserSession = (): UserSession | null => {
  const localStorageUserSession = localStorage.getItem(USER_SESSION_KEY)

  if (!localStorageUserSession) return null

  const userSession = JSON.parse(localStorageUserSession) as UserSession

  return userSession
}

const setUserSession = (userSession: UserSession) => {
  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userSession))
}

const updateUserSession = (user: User) => {
  const userSession = getUserSession()

  if (!userSession) return

  const updatedUserSession = {
    accessToken: userSession.accessToken,
    user,
  }

  setUserSession(updatedUserSession)
}

const removeUserSession = () => {
  localStorage.removeItem(USER_SESSION_KEY)
}

const userSessionLocalStorageUtils = {
  getUserSession,
  setUserSession,
  updateUserSession,
  removeUserSession,
}

export default userSessionLocalStorageUtils
