import {
  Menu, ScrollArea, Text, Title,
} from '@mantine/core'
import { useState } from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2'
import { HiOutlineCheck } from 'react-icons/hi'

interface MenuOption<ModelSchema> {
  value: ModelSchema;
  label: string;
}

interface Props<ModelSchema> {
  title: string;
  options: MenuOption<ModelSchema>[];
  selectedOptions: ModelSchema[];
  onOptionSelect: (optionValue: ModelSchema) => void;
}

export default function MenuOptions<ModelSchema>({
  title, options, selectedOptions, onOptionSelect,
}: Props<ModelSchema>) {
  const [opened, setOpened] = useState(false)

  return (
    <Menu shadow="md" width={200} opened={opened} onChange={setOpened} closeOnItemClick={false} withinPortal>
      <Menu.Target>
        <div className="flex gap-1 items-center cursor-pointer">
          <Title order={5} fw={400} className="text-white text-nowrap whitespace-nowrap">{title}</Title>
          {opened ? <HiChevronUp color="white" size={16} /> : <HiChevronDown color="white" size={16} />}
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <ScrollArea.Autosize mah={400}>
          {options.map((option) => {
            const isSelected = selectedOptions.includes(option.value)

            return (
              <Menu.Item
                key={option.value as string}
                onClick={() => onOptionSelect(option.value)}
                className={`cursor-pointer hover:bg-gray-100 ${isSelected && 'bg-violet-100 hover:bg-violet-100'}`}
                p={6}
              >
                <div className="flex gap-1 items-center">
                  {isSelected && <HiOutlineCheck color="#212529" size={16} />}
                  <Text>{option.label}</Text>
                </div>
              </Menu.Item>
            )
          })}
        </ScrollArea.Autosize>
      </Menu.Dropdown>
    </Menu>
  )
}
