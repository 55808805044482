import {
  Button, Input, Text, TextInput, Title,
} from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IMaskInput } from 'react-imask'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { faker } from '@faker-js/faker'
import { phoneValidationRegex } from '../../../shared/utils/regex'
import { Path } from '../../../shared/enums'
import { useCreateHealthcareProfessionalApplicationDetails } from '../queries'
import { formatPhoneNumber } from '../../../shared/utils/formatters'
import { ExtendedHealthcareProfessionalApplication } from '../../../models'
import { HealthcareProfessionalApplicantAttributes } from '../../../interfaces/healthcare-professional-application'
import { getPath, isDemoPath } from '../../../shared/utils/demo'

dayjs.extend(customParseFormat)

interface Props {
  onProcessHealthcareProfessionalApplication: (newHealthcareProfessionalApplication: ExtendedHealthcareProfessionalApplication) => void
}

export default function HealthcareProfessionalApplicantForm({ onProcessHealthcareProfessionalApplication }: Props) {
  const { t } = useTranslation()
  const { clinicalTrialId } = useParams()
  const navigate = useNavigate()
  const isDemo = isDemoPath()

  const {
    handleSubmit, register, control, formState: { errors, isValid },
  } = useForm<HealthcareProfessionalApplicantAttributes>({
    mode: 'onTouched',
    defaultValues: isDemo ? {
      name: faker.person.fullName(),
      phone: faker.helpers.fromRegExp(/(31) 9[0-9]{4}-[0-9]{4}/),
    } : {},
  })

  const { mutate, isLoading } = useCreateHealthcareProfessionalApplicationDetails(
    { onSuccess: (newHealthcareProfessionalApplication) => onProcessHealthcareProfessionalApplication(newHealthcareProfessionalApplication) },
  )

  const handleHealthcareProfessionalApplicantSubmit = async (applicantAttributes: HealthcareProfessionalApplicantAttributes) => {
    if (!clinicalTrialId) return navigate(getPath(Path.ClinicalTrials))
    return mutate({
      ...applicantAttributes,
      phone: formatPhoneNumber(applicantAttributes.phone),
      clinicalTrialId,
    })
  }

  return (
    <div className="flex flex-col 2xl:w-140 xl:w-140 lg:w-140 md:w-140 sm:mx-auto mx-2 sm:mt-5 mt-8">
      <Title order={1} fw={600} c="gray.9">{t('patient:details:info')}</Title>
      <Text className="mt-2" c="gray.6">{t('patient:details:info-description')}</Text>
      <form className="flex flex-col gap-3 mt-5" onSubmit={handleSubmit(handleHealthcareProfessionalApplicantSubmit)}>
        <TextInput
          placeholder={t('common:name')}
          label={t('common:name')}
          required
          error={errors.name && errors.name.message}
          {...register('name', {
            required: { value: true, message: t('common:form-error:required-field') },
          })}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
            validate: {
              validPhone: (phone) => phoneValidationRegex.test(phone) || t('common:form-error:invalid-phone'),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <Input.Wrapper label={t('common:cellphone')} required error={errors.phone && errors.phone.message}>
              <Input
                placeholder={t('common:cellphone')}
                required
                component={IMaskInput}
                mask="(00) 00000-0000"
                inputRef={ref}
                type="tel"
                {...field}
              />
            </Input.Wrapper>
          )}
        />
        <Button
          fullWidth
          color="violet"
          disabled={!isValid}
          loading={isLoading}
          className="mt-1 sm:text-sm text-md sm:h-8 h-10"
          type="submit"
        >
          {t('common:confirm')}
        </Button>
      </form>
    </div>
  )
}
