import ClinicalTrialList from '../features/clinical-trial/components/clinical-trial-list'
import ClinicalTrialListFilterMenu from '../features/clinical-trial/components/clinical-trial-filter-menu'

export default function ClinicalTrials() {
  return (
    <div className="lg:w-10/12 w-[97%] flex flex-col gap-5 mx-auto my-4">
      <ClinicalTrialListFilterMenu />
      <ClinicalTrialList />
    </div>
  )
}
