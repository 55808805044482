import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {
  messages, common, clinicalTrial, application, healthcareProfessional, patient,
} from '../internationalization/pt'

const resources = {
  pt: {
    messages,
    common,
    clinicalTrial,
    application,
    healthcareProfessional,
    patient,
  },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
