import { useState, useRef, useEffect } from 'react'
import {
  Badge,
  Button, Group, Text, Title, UnstyledButton,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'
import { ExtendedClinicalTrial } from '../../../models'

interface Props {
  clinicalTrial: ExtendedClinicalTrial;
}

export default function ClinicalTrialDetailInfo({ clinicalTrial }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const DESCRIPTION_MAX_HEIGHT = 240
  const TOLERANCE_HEIGHT = 40

  const [expanded, setExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(true)

  const descriptionRef = useRef<HTMLDivElement>(null)

  const toggleExpanded = () => setExpanded((prev) => !prev)

  useEffect(() => {
    if (descriptionRef.current) {
      if (descriptionRef.current) {
        const actualHeight = descriptionRef.current.scrollHeight

        setIsOverflowing(actualHeight > DESCRIPTION_MAX_HEIGHT + TOLERANCE_HEIGHT)
      }
    }
  }, [clinicalTrial.description])

  return (
    <div className="flex flex-col items-center gap-5">
      <div className="w-[95%] 2xl:w-[92%]">
        <div className="w-full max-w-400 h-full rounded-xl py-8 lg:py-10 2xl:py-12 bg-cover bg-no-repeat bg-center bg-clinical-trial-background-image">
          <div className="flex flex-col w-[93%] m-auto">
            <Title order={3} fw={600} className="text-white">{clinicalTrial.title}</Title>
            {clinicalTrial.subtitle && <Title order={4} fw={500} className="text-white mb-3">{clinicalTrial.subtitle}</Title>}
            <Button
              bg="white"
              c="violet.7"
              className="sm:w-60 w-full xl:text-md sm:text-sm text-lg xl:h-10 sm:h-10 h-12 mt-4"
              onClick={() => navigate(getPath(Path.HealthCareProfessionalApplication.replace(':clinicalTrialId', clinicalTrial.id)))}
            >
              {t('clinicalTrial:detail:register-patient')}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 w-[88%]">
        <div className="flex flex-col">
          <Title order={6} fw={400} c="gray.6" mb={5}>{t('clinicalTrial:detail:research-centers')}</Title>
          <div className="flex gap-2">
            {clinicalTrial.researchCenters.map((researchCenter) => (
              <Badge color="gray.1" c="black" tt="none" size="lg" fw="400">{researchCenter.name}</Badge>
            ))}
          </div>
        </div>
        <div className="2xl:w-8/12 xl:w-10/12 lg:mx-1">
          <Group
            ref={descriptionRef}
            mah={!expanded && isOverflowing ? DESCRIPTION_MAX_HEIGHT : undefined}
            className="relative transition-all ease-in-out duration-300 overflow-hidden"
          >
            <Text size="md" c="gray.9" fw={600}>{t('clinicalTrial:detail:know-more')}</Text>
            <Text
              size="md"
              className="text-gray-700"
              dangerouslySetInnerHTML={{ __html: clinicalTrial.description }}
            />
            {!expanded && isOverflowing && (
            <div className="absolute inset-x-0 bottom-0 h-4 bg-gradient-to-b from-transparent to-white" />
            )}
          </Group>
          {isOverflowing && (
          <UnstyledButton
            variant="subtle"
            size="xs"
            className="mt-2"
            onClick={toggleExpanded}
          >
            {expanded ? (
              <div className="flex gap-3 items-center">
                <Text size="md" fw={500} className="text-gray-900">
                  {t('clinicalTrial:detail:show-less')}
                </Text>
                <FaChevronUp size={14} className="text-gray-500" />
              </div>
            ) : (
              <div className="flex gap-3 items-center">
                <Text size="md" fw={500} className="text-gray-900">
                  {t('clinicalTrial:detail:read-more')}
                </Text>
                <FaChevronDown size={14} className="text-gray-500" />
              </div>
            )}
          </UnstyledButton>
          )}
        </div>
      </div>
    </div>
  )
}
