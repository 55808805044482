import clinicalTrial from './controllers/clinical-trials'
import auth from './controllers/auth'
import healtcareProfessionals from './controllers/healtcare-professionals'
import healthcareProfessionalApplications from './controllers/healthcare-professional-applications'
import shortUrls from './controllers/short-urls'
import patients from './controllers/patients'
import researchCenters from './controllers/research-centers'

const api = {
  clinicalTrial,
  auth,
  healtcareProfessionals,
  healthcareProfessionalApplications,
  shortUrls,
  patients,
  researchCenters,
}

export default api
