import { kyBase } from '../config/ky'
import { SignUpPatientRequest } from '../requests/patients'
import { SignUpPatientResponse } from '../responses/patients'

const PATIENTS_BASE_URL = 'patients'

const signUpPatient = async (request: SignUpPatientRequest, signUpToken: string) => kyBase
  .post(`${PATIENTS_BASE_URL}/sign-up`, {
    json: request,
    headers: {
      Authorization: signUpToken,
    },
  }).json<SignUpPatientResponse>()

export default {
  signUpPatient,
}
