import { toast } from 'react-toastify'
import i18next from 'i18next'
import { jwtDecode } from 'jwt-decode'
import { api } from '../../../libs'
import { AccessToken, UserSession } from '../../../interfaces/auth'
import { SignUpHealthcaProfessionalAttributes } from '../../../interfaces/healthcare-professional'
import { userSessionLocalStorageUtils } from '../../auth/utils'
import AccessTokenPayload from '../../../interfaces/auth/access-token-payload'

const signUpHealthcareProfessional = async (
  signUpHealthcaProfessionalAttributes: SignUpHealthcaProfessionalAttributes,
  signUpToken: string,
) => {
  let signUpResponse: AccessToken

  try {
    signUpResponse = await api.healtcareProfessionals.signUpHealthcareProfessional(signUpHealthcaProfessionalAttributes, signUpToken)
  } catch (error: unknown) {
    toast.error(i18next.t('messages:error:default'))
    throw error
  }
  const { accessToken } = signUpResponse

  const accessTokenPayload = jwtDecode<AccessTokenPayload>(accessToken)

  const userSession: UserSession = {
    accessToken,
    user: accessTokenPayload.user,
  }

  userSessionLocalStorageUtils.setUserSession(userSession)

  return userSession
}

const healthcareProfessionalService = {
  signUpHealthcareProfessional,
}

export default healthcareProfessionalService
